import React, { createContext, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';

// action - state management
import authReducer from 'store/reducers/auth';

// project import
import Loader from 'components/Loader';
import { AuthProps, JWTContextType } from 'types/auth';
import { LOGIN, LOGOUT } from 'store/reducers/actions';
import { loginRequest } from '_api/api-auth';
import useLocalStorage from 'hooks/useLocalStorage';
import { giverApi as axios } from 'utils/axios';

// const
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const GiverAuthContext = createContext<JWTContextType | null>(null);

export const GiverAuthProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [authProps, saveAuthProps] = useLocalStorage<AuthProps>('giver_auth_props', initialState);
  const navigate = useNavigate();

  if (authProps?.token) {
    axios.defaults.headers.common['Authorization'] = authProps.token;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const expectedError = error.response && error.response.status === 401;
      if (expectedError) {
        logout();
        navigate('login?api=giver&lastPath=' + window.location.pathname, { replace: true });
      }
      return Promise.reject(error);
    }
  );

  useEffect(
    () => {
      if (authProps && authProps.user?.id && authProps?.token) {
        dispatch({
          type: LOGIN,
          payload: authProps
        });
      } else {
        dispatch({
          type: LOGOUT
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const login = async (email: string, password: string, isKeepSignIn: boolean) => {
    try {
      const result = await loginRequest('giver', email, password);
      const data = result.data;
      const userAuthProps: AuthProps = {
        isLoggedIn: true,
        isInitialized: true,
        user: {
          id: data.id,
          email: data.email,
          name: data.userName || 'user',
          role: 'Admin'
        },
        token: result.headers.authorization
      };

      dispatch({
        type: LOGIN,
        payload: userAuthProps
      });

      if (isKeepSignIn) {
        saveAuthProps(userAuthProps);
      }

      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const resetPassword = (email: string) => {
    return Promise.resolve();
  };

  const register = (email: string, password: string, firstName: string, lastName: string) => {
    return Promise.resolve();
  };

  const logout = () => {
    saveAuthProps(initialState);
    dispatch({
      type: LOGOUT
    });
  };

  const updateProfile = () => {};
  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <GiverAuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        updateProfile,
        resetPassword
      }}
    >
      {children}
    </GiverAuthContext.Provider>
  );
};

export default GiverAuthContext;
