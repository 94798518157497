// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { BrandStateProps } from 'types/brand';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: BrandStateProps = {
  error: null,
  brand: undefined,
  brands: [],
  meta: {
    count: 0,
    items: 0,
    page: 1
  },
  filters: {
    onlyActive: true
  }
};

const brand = createSlice({
  name: 'brand',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET BRAND LIST
    getBrandListSuccess(state, action) {
      state.brands = camelize(action.payload.brands, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    },

    // GET BRAND DETAIL
    getBrandDetailSuccess(state, action) {
      state.brand = camelize(action.payload.brand, { recursive: true });
    }
  }
});

// Reducer
export default brand.reducer;

// ----------------------------------------------------------------------

export function getBrandList(clientId?: number, paging?: { page?: string; perPage?: string }, filters?: { onlyActive?: boolean }) {
  return async () => {
    try {
      const response = await axios.get(`/admins/brands`, {
        params: {
          sorts: ['id desc'],
          'query[client_id_eq]': clientId,
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(Boolean(filters?.onlyActive) ? { 'query[active_eq]': true } : '')
        }
      });
      dispatch(brand.actions.getBrandListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      // dispatch(brand.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get brands"
        })
      );
    }
  };
}

export function getBrandDetail(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/admins/brands/${id}`, {});
      dispatch(brand.actions.getBrandDetailSuccess(response.data));
    } catch (error) {
      // dispatch(brand.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get brand"
        })
      );
    }
  };
}

export function createBrandDetail(data: any) {
  return async () => {
    try {
      await axios.post(`/admins/brands`, getBrandFormData(data), {});
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Brand created successfully'
        })
      );
    } catch (error) {
      // dispatch(brand.actions.hasError("Error. Can't create brand."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't create brand"
        })
      );
      return Promise.reject(new Error("Error. Can't create brand."));
    }
  };
}

export function updateBrandDetail(id: number, data: any) {
  return async () => {
    try {
      await axios.put(`/admins/brands/${id}`, getBrandFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Brand updated successfully'
        })
      );
    } catch (error) {
      // dispatch(brand.actions.hasError("Error. Can't update brand."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update brand"
        })
      );
      return Promise.reject(new Error("Error. Can't update brand."));
    }
  };
}

export function deleteBrand(itemIds: string[], clientId?: number, paging?: {}, filters?: {}) {
  return async () => {
    try {
      await axios.delete(`/admins/brands/${itemIds[0]}`, {});
      dispatch(getBrandList(clientId, paging, filters));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Brand deleted successfully'
        })
      );
    } catch (error) {
      // dispatch(brand.actions.hasError("Error. Can't delete brand."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't delete brand"
        })
      );
      return Promise.reject(new Error("Error. Can't delete brand."));
    }
  };
}

function getBrandFormData(data: any): FormData {
  const formData = new FormData();

  formData.append('brand[title_en]', data.titleEn);
  formData.append('brand[title_ru]', data.titleRu);
  formData.append('brand[description_en]', data.descriptionEn);
  formData.append('brand[description_ru]', data.descriptionRu);
  formData.append('brand[client_id]', data.clientId);
  formData.append('brand[market_type]', data.marketType || '');
  formData.append('brand[weight]', data.weight || '');
  formData.append('brand[active]', data.active);
  formData.append('brand[profile_id]', data.profileId || '');
  if (data.links.length !== 0) {
    data.links.forEach((item: any, index: number) => {
      formData.append(`brand[links][][title_en]`, item.titleEn);
      formData.append(`brand[links][][title_ru]`, item.titleRu);
      formData.append(`brand[links][][url]`, item.url);
      formData.append(`brand[links][][type]`, item.type);
    });
  } else {
    formData.append('brand[links][]', '');
  }
  if (data.logo instanceof File) {
    formData.append('brand[logo]', data.logo);
  }
  if (data.backgroundImage instanceof File) {
    formData.append('brand[background_image]', data.backgroundImage);
  } else if (data.backgroundImage === null) {
    formData.append('brand[remove_background_image]', 'true');
  }

  return formData;
}
