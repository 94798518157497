// third-party
import { combineReducers } from 'redux';

// project import
import regular from './regular';
import brands from './brands';

// ==============================|| COMBINE REDUCERS ||============================== //

const statistics = combineReducers({
  regular,
  brands
});

export default statistics;
