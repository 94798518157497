import { useState } from 'react';

// project import
import Editor, { EditorContentChanged } from 'components/editor';
import MainCard from 'components/MainCard';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

// ==============================|| MARKDOWN PAGE ||============================== //

const MarkdownPage = () => {
  const [editorMarkdownValue, setEditorMarkdownValue] = useState<string>('');

  const onEditorContentChanged = (content: EditorContentChanged) => {
    setEditorMarkdownValue(content.markdown);
  };

  return (
    <MainCard title="Markdown">
      <Editor onChange={onEditorContentChanged}></Editor>
      <Box mt={4}>
        <Typography variant="h5" component="h5">
          Markdown output:
        </Typography>
        <p>{editorMarkdownValue}</p>
      </Box>
    </MainCard>
  );
};

export default MarkdownPage;
