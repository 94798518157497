import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';

// ==============================|| ACCESS GUARD ||============================== //

const AccessGuard: FC<{ component: JSX.Element; roles: string[] }> = ({ component, roles }) => {
  const role = useAuth('lootbox')?.user?.role as string;
  const navigate = useNavigate();

  useEffect(() => {
    if (!roles.includes(role)) navigate('/403', { replace: true });
  }, [navigate, role, roles]);

  return component;
};

export default AccessGuard;
