// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';
import dayjs from 'dayjs';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { PromocodeStateProps, Promocode } from 'types/promocode';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: PromocodeStateProps = {
  error: null,
  promocode: undefined,
  promocodes: [],
  promocodeUsers: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {
    brandId: '',
    lootboxId: '',
    usageTypeId: '',
    onlyActive: true
  }
};

const promocode = createSlice({
  name: 'promocode',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET PROMOCODE LIST
    getPromocodeListSuccess(state, action) {
      state.promocodes = camelize(action.payload.promo_codes, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    },

    // GET PROMOCODE USER LIST
    getPromocodeUserListSuccess(state, action) {
      state.promocodeUsers = camelize(action.payload.users, { recursive: true });
    },

    // // GET PROMOCODE DETAIL
    getPromocodeDetailSuccess(state, action) {
      state.promocode = camelize(action.payload.promo_code, { recursive: true });
    }
  }
});

// Reducer
export default promocode.reducer;

// ----------------------------------------------------------------------

export function getPromocodeList(
  clientId?: number,
  paging?: { page?: string; perPage?: string },
  filters?: { brandId?: string; lootboxId?: string; usageTypeId?: string; onlyActive?: boolean }
) {
  return async () => {
    try {
      const response = await axios.get(`/admins/promo_codes`, {
        params: {
          sorts: ['id desc'],
          'query[lootbox_brand_client_id_eq]': clientId,
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(Boolean(filters?.onlyActive) ? { 'query[active_eq]': true } : ''),
          ...(filters?.brandId !== undefined ? { 'query[lootbox_brand_id_eq]': filters?.brandId } : ''),
          ...(filters?.lootboxId !== undefined ? { 'query[lootbox_id_eq]': filters?.lootboxId } : ''),
          ...(filters?.usageTypeId !== undefined ? { 'query[usage_type_eq]': filters?.usageTypeId } : '')
        }
      });
      dispatch(promocode.actions.getPromocodeListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      // dispatch(promocode.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get promocodes"
        })
      );
    }
  };
}

export function getPromocodeUserList(clientId?: number, profileId?: string, userId?: number) {
  return async () => {
    try {
      const response = await axios.get(`/admins/users`, {
        params: {
          sorts: ['id desc'],
          page: 1,
          per_page: 10,
          'query[client_id_eq]': clientId,
          ...(profileId !== undefined ? { 'query[profile_id_eq]': profileId } : ''),
          ...(userId !== undefined ? { 'query[id_eq]': userId } : '')
        }
      });
      dispatch(promocode.actions.getPromocodeUserListSuccess(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get user list"
        })
      );
    }
  };
}

export function getPromocodeDetail(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/admins/promo_codes/${id}`, {});
      dispatch(promocode.actions.getPromocodeDetailSuccess(response.data));
    } catch (error) {
      // dispatch(promocode.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get promocode"
        })
      );
    }
  };
}

export function createPromocodeDetail(data: any) {
  return async () => {
    try {
      const response = await axios.post(`/admins/promo_codes`, getPromocodeFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Promocodes created successfully',
          downloadData: response.data.promo_codes.map((item: Promocode) => ({ code: item.code })),
          downloadName: 'Codes_for_lootbox_' + data.lootboxId + '_-_' + dayjs().format('DD-MM-YYYY'),
          autoHideDuration: 10000
        })
      );
    } catch (error) {
      // dispatch(promocode.actions.hasError("Error. Can't create promocode."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't create promocode"
        })
      );
      return Promise.reject(new Error("Error. Can't create promocode."));
    }
  };
}

export function updatePromocodeDetail(id: number, data: any) {
  return async () => {
    try {
      await axios.put(`/admins/promo_codes/${id}`, getPromocodeFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Promocode updated successfully'
        })
      );
    } catch (error) {
      // dispatch(promocode.actions.hasError("Error. Can't update promocode."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update promocode."
        })
      );
      return Promise.reject(new Error("Error. Can't update promocode"));
    }
  };
}

export function deletePromocode(itemIds: string[], clientId?: number, paging?: {}, filters?: {}) {
  return async () => {
    try {
      await axios.delete(`/admins/promo_codes`, {
        data: {
          ids: itemIds
        }
      });
      dispatch(getPromocodeList(clientId, paging, filters));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Promocode deleted successfully'
        })
      );
    } catch (error) {
      // dispatch(promocode.actions.hasError("Error. Can't delete promocode."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't delete promocode"
        })
      );
      return Promise.reject(new Error("Error. Can't delete promocode."));
    }
  };
}

function getPromocodeFormData(data: any): FormData {
  const formData = new FormData();

  formData.append('promo_code[usage_type]', data.usageType);
  formData.append('promo_code[lootbox_id]', data.lootboxId);
  formData.append('promo_code[comment]', data.comment || '');
  formData.append('promo_code[start_at]', data.startAt || '');
  formData.append('promo_code[end_at]', data.endAt || '');
  data.customCode && data.code && formData.append('promo_code[code]', data.code.toUpperCase());
  data.usageType === 'personal' && data.assignedUser && formData.append('promo_code[assigned_user_id]', data.assignedUser.id);
  data.reusable && formData.append('promo_code[days_amount]', data.daysAmount);
  data.reusable && formData.append('promo_code[usage_amount_per_period]', data.usageAmountPerPeriod);
  data.reusable && data.maxUsageAmount && formData.append('promo_code[max_usage_amount]', data.maxUsageAmount);
  formData.append('promo_codes_amount', !data.customCode && data.promoCodesAmount ? data.promoCodesAmount : 1);
  formData.append('promo_code[active]', data.active);

  return formData;
}
