import { Box, InputLabel, MenuItem, OutlinedInput, Select, Stack } from '@mui/material';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { getClientList, setCurrentClient } from 'store/reducers/client';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const ClientSelect: FC = () => {
  const dispatch = useDispatch();
  const { clients } = useSelector((state) => state.client);

  useEffect(() => {
    dispatch(getClientList());
  }, [dispatch]);

  const handleChangeClient = (e: any) => {
    localStorage.setItem('selected', e.target.value.toString());
    dispatch(setCurrentClient(e.target.value));
  };

  return (
    <Box width={340}>
      <Stack spacing={1} direction="row">
        <Box width={120} display="flex" alignItems="center">
          <InputLabel>Client app:</InputLabel>
        </Box>
        <Select
          fullWidth
          input={<OutlinedInput name="clientId" defaultValue="all" />}
          MenuProps={MenuProps}
          displayEmpty
          onChange={handleChangeClient}
          value={clients?.length ? localStorage.getItem('selected') || 2 : ''}
          disabled={true}
        >
          {(clients || []).map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Box>
  );
};

export default ClientSelect;
