// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { FlagOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  FlagOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const giverSection: NavItemType = {
  id: 'giver-section',
  title: 'Giver service',
  type: 'group',
  children: [
    {
      id: 'rchallenges',
      title: <FormattedMessage id="rchallenges" />,
      type: 'item',
      url: '/rchallenges',
      icon: icons.FlagOutlined,
      api: 'giver'
    }
  ]
};

export default giverSection;
