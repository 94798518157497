// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../../../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { FollowingsStateProps } from 'types/giver/events';
import { giverApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: FollowingsStateProps = {
  error: null,
  followingList: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {}
};

const followings = createSlice({
  name: 'followings',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CHALLENGE LIST
    getFollowingListSuccess(state, action) {
      state.followingList = camelize(action.payload.data, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    }
  }
});

// Reducer
export default followings.reducer;

// ----------------------------------------------------------------------

export function getFollowingList(
  filters?: {
    profileId?: string;
    followingId?: string;
    deleted?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
  },
  paging?: { page?: string; perPage?: string }
) {
  return async () => {
    try {
      const response = await axios.get(`/admin/events/followings`, {
        params: {
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(filters?.profileId !== undefined ? { 'query[profile_id_eq]': filters?.profileId } : ''),
          ...(filters?.followingId !== undefined ? { 'query[following_id_eq]': filters?.followingId } : ''),
          ...(filters?.deleted !== undefined ? { 'query[deleted_eq]': filters?.deleted } : ''),
          ...(filters?.createdAtFrom && { 'query[created_at_gteq]': filters?.createdAtFrom }),
          ...(filters?.createdAtTo && { 'query[created_at_lteq]': filters?.createdAtTo })
        }
      });
      dispatch(followings.actions.getFollowingListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      dispatch(followings.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get followings"
        })
      );
    }
  };
}
