import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// project import
import config from 'config';
import useAuth from 'hooks/useAuth';

// types
import { GuardProps } from 'types/auth';

// ==============================|| GUEST GUARD ||============================== //

const GuestGuard = ({ children }: GuardProps) => {
  const [searchParams] = useSearchParams();
  const lastPath = searchParams.get('lastPath') || undefined;
  const api = searchParams.get('api') || 'lootbox';
  const { isLoggedIn } = useAuth(api);
  const navigate = useNavigate();
  const defaultPath = api === 'giver' ? config.giverDefaultPath : config.defaultPath;

  useEffect(() => {
    if (isLoggedIn) {
      navigate(lastPath || defaultPath, { replace: true });
    }
  }, [lastPath, isLoggedIn, navigate, defaultPath]);

  return children;
};

export default GuestGuard;
