import axios from 'axios';
import config from 'config';

export const lootboxApi = axios.create({
  baseURL: config.lootboxApiHost + '/api/v1'
});

export const giverApi = axios.create({
  baseURL: config.giverApiHost + '/api/v1'
});
