import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import AccessGuard from 'utils/route-guard/AccessGuard';
import MarkdownPage from 'pages/markdown';

// pages routing
const AdminListPage = Loadable(lazy(() => import('pages/admin-list')));
const AdminEditPage = Loadable(lazy(() => import('pages/admin-edit')));
const BrandListPage = Loadable(lazy(() => import('pages/content/brand-list')));
const BrandEditPage = Loadable(lazy(() => import('pages/content/brand-edit')));
const CollectionListPage = Loadable(lazy(() => import('pages/content/collection-list')));
const CollectionEditPage = Loadable(lazy(() => import('pages/content/collection-edit')));
const EntitiesListPage = Loadable(lazy(() => import('pages/content/entity-list')));
const EntityEditPage = Loadable(lazy(() => import('pages/content/entity-edit')));
const LootBoxListPage = Loadable(lazy(() => import('pages/content/lootbox-list')));
const LootBoxEditPage = Loadable(lazy(() => import('pages/content/lootbox-edit')));
const PromocodesListPage = Loadable(lazy(() => import('pages/content/promocode-list')));
const PromocodeEditPage = Loadable(lazy(() => import('pages/content/promocode-edit')));
const PromoactionsListPage = Loadable(lazy(() => import('pages/content/promoaction-list')));
const PromoactionEditPage = Loadable(lazy(() => import('pages/content/promoaction-edit')));
const PromoactionCodesUpload = Loadable(lazy(() => import('pages/content/promoaction-codes-upload')));
const ChallengesListPage = Loadable(lazy(() => import('pages/content/challenge-list')));
const ChallengeEditPage = Loadable(lazy(() => import('pages/content/challenge-edit')));
const RChallengeListPage = Loadable(lazy(() => import('pages/giver/rchallenge-list')));
const RChallengeEditPage = Loadable(lazy(() => import('pages/giver/rchallenge-edit')));
const UsersListPage = Loadable(lazy(() => import('pages/support/user-list')));
const UserEditPage = Loadable(lazy(() => import('pages/support/user-edit')));
const SettingsPage = Loadable(lazy(() => import('pages/settings-page')));
const BlockchainPage = Loadable(lazy(() => import('pages/blockchain-monitoring/blockchain-page')));
const Error403 = Loadable(lazy(() => import('pages/maintenance/403')));
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'settings',
          element: <AccessGuard component={<SettingsPage />} roles={['super_admin']} />
        },
        {
          path: 'blockchain',
          element: <AccessGuard component={<BlockchainPage />} roles={['super_admin']} />
        },
        {
          path: 'challenges',
          element: <ChallengesListPage />
        },
        {
          path: 'challenges/new',
          element: <AccessGuard component={<ChallengeEditPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'challenges/edit/:id',
          element: <AccessGuard component={<ChallengeEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'challenges/view/:id',
          element: <ChallengeEditPage mode="view" />
        },
        {
          path: 'entities',
          element: <EntitiesListPage />
        },
        {
          path: 'entities/new',
          element: <AccessGuard component={<EntityEditPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'entities/edit/:id',
          element: <AccessGuard component={<EntityEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'entities/view/:id',
          element: <EntityEditPage mode="view" />
        },
        {
          path: 'promocodes',
          element: <AccessGuard component={<PromocodesListPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'promocodes/new',
          element: <AccessGuard component={<PromocodeEditPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'promocodes/edit/:id',
          element: <AccessGuard component={<PromocodeEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'promocodes/view/:id',
          element: <AccessGuard component={<PromocodeEditPage mode="view" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'lootboxes',
          element: <LootBoxListPage />
        },
        {
          path: 'lootboxes/new',
          element: <AccessGuard component={<LootBoxEditPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'lootboxes/edit/:id',
          element: <AccessGuard component={<LootBoxEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'lootboxes/view/:id',
          element: <LootBoxEditPage mode="view" />
        },
        {
          path: 'markdown',
          element: <MarkdownPage />
        },
        {
          path: 'collections',
          element: <CollectionListPage />
        },
        {
          path: 'collections/new',
          element: <AccessGuard component={<CollectionEditPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'collections/edit/:id',
          element: <AccessGuard component={<CollectionEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'collections/view/:id',
          element: <CollectionEditPage mode="view" />
        },
        {
          path: 'brands',
          element: <BrandListPage />
        },
        {
          path: 'brands/new',
          element: <AccessGuard component={<BrandEditPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'brands/edit/:id',
          element: <AccessGuard component={<BrandEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'brands/view/:id',
          element: <BrandEditPage mode="view" />
        },
        {
          path: 'admins',
          element: <AccessGuard component={<AdminListPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'admins/new',
          element: <AccessGuard component={<AdminEditPage />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'admins/edit/:id',
          element: <AccessGuard component={<AdminEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'admins/view/:id',
          element: <AccessGuard component={<AdminEditPage mode="view" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'users',
          element: <UsersListPage />
        },
        {
          path: 'users/edit/:id',
          element: <AccessGuard component={<UserEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'users/view/:id',
          element: <UserEditPage mode="view" />
        },
        {
          path: 'promoactions',
          element: <PromoactionsListPage />
        },
        {
          path: 'promoactions/:id/upload',
          element: <AccessGuard component={<PromoactionCodesUpload />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'promoactions/edit/:id',
          element: <AccessGuard component={<PromoactionEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'promoactions/view/:id',
          element: <PromoactionEditPage mode="view" />
        },
        {
          path: 'rchallenges',
          element: <RChallengeListPage />
        },
        {
          path: 'rchallenges/new',
          element: <RChallengeEditPage />
        },
        {
          path: 'rchallenges/edit/:id',
          element: <AccessGuard component={<RChallengeEditPage mode="edit" />} roles={['super_admin', 'admin']} />
        },
        {
          path: 'rchallenges/view/:id',
          element: <RChallengeEditPage mode="view" />
        }
      ]
    },
    {
      path: '403',
      element: <Error403 />
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    }
  ]
};

export default MainRoutes;
