// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../../../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { PostLikesStateProps } from 'types/giver/events';
import { giverApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: PostLikesStateProps = {
  error: null,
  postLikeList: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {}
};

const postLikes = createSlice({
  name: 'postLikes',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CHALLENGE LIST
    getPostLikeListSuccess(state, action) {
      state.postLikeList = camelize(action.payload.data, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    }
  }
});

// Reducer
export default postLikes.reducer;

// ----------------------------------------------------------------------

export function getPostLikeList(
  filters?: {
    profileId?: number;
    postId?: string;
    deleted?: string;
    authorProfileId?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
    parentResourceCreatedAtFrom?: string;
    parentResourceCreatedAtTo?: string;
  },
  paging?: { page?: string; perPage?: string }
) {
  return async () => {
    try {
      const response = await axios.get(`/admin/events/post_likes`, {
        params: {
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(filters?.profileId !== undefined ? { 'query[profile_id_eq]': filters?.profileId } : ''),
          ...(filters?.postId !== undefined ? { 'query[post_id_eq]': filters?.postId[0] } : ''),
          ...(filters?.deleted !== undefined ? { 'query[deleted_eq]': filters?.deleted } : ''),
          ...(filters?.authorProfileId !== undefined ? { 'query[author_profile_id_eq]': filters?.authorProfileId } : ''),
          ...(filters?.createdAtFrom && { 'query[created_at_gteq]': filters?.createdAtFrom }),
          ...(filters?.createdAtTo && { 'query[created_at_lteq]': filters?.createdAtTo }),
          ...(filters?.parentResourceCreatedAtFrom && { 'query[parent_resource_created_at_gteq]': filters?.parentResourceCreatedAtFrom }),
          ...(filters?.parentResourceCreatedAtTo && { 'query[parent_resource_created_at_lteq]': filters?.parentResourceCreatedAtTo })
        }
      });
      dispatch(postLikes.actions.getPostLikeListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      dispatch(postLikes.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get post likes"
        })
      );
    }
  };
}
