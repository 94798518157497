// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { AdminStateProps } from 'types/admin';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: AdminStateProps = {
  error: null,
  admin: undefined,
  admins: [],
  meta: {
    count: 0,
    items: 0,
    page: 1
  },
  filters: {
    role: ''
  }
};

const admin = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ADMIN LIST
    getAdminListSuccess(state, action) {
      state.admins = camelize(action.payload.admins, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    },

    // GET ADMIN DETAIL
    getAdminDetailSuccess(state, action) {
      state.admin = camelize(action.payload.admin, { recursive: true });
    }
  }
});

// Reducer
export default admin.reducer;

// ----------------------------------------------------------------------

export function getAdminList(clientId?: number, paging?: { page?: string; perPage?: string }, filters?: { role?: string }) {
  return async () => {
    try {
      const response = await axios.get(`/admins/profiles`, {
        params: {
          sorts: ['id desc'],
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(filters?.role !== undefined ? { 'query[role_eq]': filters?.role } : '')
        }
      });
      dispatch(admin.actions.getAdminListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      // dispatch(admin.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get admins"
        })
      );
    }
  };
}

export function getAdminDetail(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/admins/profiles/${id}`, {});
      dispatch(admin.actions.getAdminDetailSuccess(response.data));
    } catch (error) {
      // dispatch(admin.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get admin"
        })
      );
    }
  };
}

export function createAdminDetail(data: any) {
  return async () => {
    try {
      await axios.post(`/admins/profiles`, getAdminFormData(data), {});
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Admin created successfully'
        })
      );
    } catch (error) {
      // dispatch(admin.actions.hasError("Error. Can't create admin."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't create admin"
        })
      );
      return Promise.reject(new Error("Error. Can't create admin."));
    }
  };
}

export function updateAdminDetail(id: number, data: any) {
  return async () => {
    try {
      await axios.put(`/admins/profiles/${id}`, getAdminFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Admin updated successfully'
        })
      );
    } catch (error) {
      // dispatch(admin.actions.hasError("Error. Can't update admin."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update admin"
        })
      );
      return Promise.reject(new Error("Error. Can't update admin."));
    }
  };
}

export function deleteAdmin(itemIds: string[], clientId?: number, paging?: {}, filters?: {}) {
  return async () => {
    try {
      await axios.delete(`/admins/profiles`, {
        data: {
          ids: itemIds
        }
      });
      dispatch(getAdminList(clientId, paging, filters));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Admin deleted successfully'
        })
      );
    } catch (error) {
      // dispatch(admin.actions.hasError("Error. Can't delete admin."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't delete admin"
        })
      );
      return Promise.reject(new Error("Error. Can't delete admin."));
    }
  };
}

function getAdminFormData(data: any): FormData {
  const formData = new FormData();

  formData.append('admin[email]', data.email);
  formData.append('admin[role]', data.role);
  data.password && formData.append('admin[password]', data.password);

  return formData;
}
