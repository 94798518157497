// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize, decamelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { RChallengeStateProps } from 'types/giver/rchallenge';
import { giverApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: RChallengeStateProps = {
  error: null,
  rchallenge: undefined,
  rchallenges: [],
  // meta: {
  //   count: 0,
  //   items: 10,
  //   page: 1
  // },
  filters: {
    // brandId: ''
  }
};

const rchallenge = createSlice({
  name: 'rchallenge',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CHALLENGE LIST
    getRChallengeListSuccess(state, action) {
      state.rchallenges = camelize(action.payload.data, { recursive: true });
      // state.meta = action.payload.meta;
      // state.filters = action.payload.filters;
    },

    // GET CHALLENGE DETAIL
    getRChallengeDetailSuccess(state, action) {
      state.rchallenge = camelize(action.payload.data, { recursive: true });
    }
  }
});

// Reducer
export default rchallenge.reducer;

// ----------------------------------------------------------------------

export function getRChallengeList(filters?: { brandId?: string }) {
  return async () => {
    try {
      const response = await axios.get(`/challenges_settings`, {
        params: {
          // ...(paging?.page && { page: paging?.page }),
          // ...(paging?.perPage && { per_page: paging?.perPage }),
          // ...(filters?.brandId !== undefined ? { 'query[lootbox_brand_id_eq]': filters?.brandId } : '')
        }
      });
      dispatch(rchallenge.actions.getRChallengeListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      // dispatch(rchallenge.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get challenges"
        })
      );
    }
  };
}

export function getRChallengeDetail(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/challenges_settings/${id}`, {});
      dispatch(rchallenge.actions.getRChallengeDetailSuccess(response.data));
    } catch (error) {
      // dispatch(rchallenge.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get challenge"
        })
      );
    }
  };
}

export function createRChallengeDetail(data: any) {
  return async () => {
    try {
      await axios.post(`/challenges_settings`, getRChallengeJSON(data), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Challenge created successfully'
        })
      );
    } catch (error) {
      // dispatch(rchallenge.actions.hasError("Error. Can't create challenge."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't create challenge"
        })
      );
      return Promise.reject(new Error("Error. Can't create challenge."));
    }
  };
}

export function updateRChallengeDetail(id: string, data: any) {
  return async () => {
    try {
      await axios.put(`/challenges_settings/${id}`, getRChallengeJSON(data), {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      dispatch(getRChallengeDetail(id));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Challenge updated successfully'
        })
      );
    } catch (error) {
      // dispatch(rchallenge.actions.hasError("Error. Can't update challenge."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update challenge"
        })
      );
      return Promise.reject(new Error("Error. Can't update challenge."));
    }
  };
}

export function deleteRChallenge(itemIds: string[], filters?: {}) {
  return async () => {
    try {
      await axios.delete(`/challenges_settings/${itemIds[0]}`, {});
      dispatch(getRChallengeList(filters));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Challenge deleted successfully'
        })
      );
    } catch (error) {
      // dispatch(challenge.actions.hasError("Error. Can't delete challenge."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't delete challenge"
        })
      );
      return Promise.reject(new Error("Error. Can't delete challenge."));
    }
  };
}

function getRChallengeJSON(data: any) {
  const cleanObject = (obj: { [key: string]: any }) => {
    let newObject = Array.isArray(obj) ? [] : ({} as { [key: string]: any });
    for (let key in obj) {
      let value = obj[key];
      if (typeof value === 'object') {
        let newValueObject = cleanObject(value);
        if (JSON.stringify(newValueObject) !== '{}' && JSON.stringify(newValueObject) !== '[]') {
          Array.isArray(obj) ? newObject.push(newValueObject) : (newObject[key] = newValueObject);
        }
      } else if (value !== '') {
        Array.isArray(obj) ? newObject.push(value) : (newObject[key] = value);
      }
    }
    return decamelize(newObject, { recursive: true });
  };
  return cleanObject(data);
}
