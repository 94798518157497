import { lootboxApi, giverApi } from 'utils/axios';

export const loginRequest = async (apiName: string, email: string, password: string) => {
  const data = {
    email,
    password
  };
  const response =
    apiName === 'giver' ? await giverApi.post(`/admins/auth/sign_in`, data) : await lootboxApi.post(`/admins/auth/sign_in`, data);

  return response;
};
