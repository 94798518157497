import { createSlice } from '@reduxjs/toolkit';

// types
import { SnackbarProps } from 'types/snackbar';

const initialState: SnackbarProps = {
  action: false,
  open: false,
  message: 'Note archived',
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right'
  },
  variant: 'alert',
  transition: 'Fade',
  close: false,
  autoHideDuration: 3000,
  downloadData: [],
  downloadName: 'Lootbox_data',
  severity: 'info'
};

// ==============================|| SLICE - SNACKBAR ||============================== //

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    openSnackbar(state, action) {
      const { message, anchorOrigin, variant, transition, close, autoHideDuration, downloadData, downloadName, severity } = action.payload;
      state.action = !state.action;
      state.open = true;
      state.message = message || initialState.message;
      state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
      state.variant = variant || initialState.variant;
      state.transition = transition || initialState.transition;
      state.close = close || initialState.close;
      state.autoHideDuration = autoHideDuration || 3000;
      state.downloadData = downloadData || initialState.downloadData;
      state.downloadName = downloadName || initialState.downloadName;
      state.severity = severity || initialState.severity;
    },

    closeSnackbar(state) {
      state.open = false;
    }
  }
});

export default snackbar.reducer;

export const { closeSnackbar, openSnackbar } = snackbar.actions;
