// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../../../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { AnswersStateProps } from 'types/giver/events';
import { giverApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: AnswersStateProps = {
  error: null,
  answerList: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {}
};

const answer = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ANSWER LIST
    getAnswerListSuccess(state, action) {
      state.answerList = camelize(action.payload.data, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    }
  }
});

// Reducer
export default answer.reducer;

// ----------------------------------------------------------------------

export function getAnswerList(
  filters?: {
    profileId?: number;
    pollId?: string;
    pollProfileId?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
  },
  paging?: { page?: string; perPage?: string }
) {
  return async () => {
    try {
      const response = await axios.get(`/admin/events/answers`, {
        params: {
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(filters?.profileId !== undefined ? { 'query[profile_id_eq]': filters?.profileId } : ''),
          ...(filters?.pollId !== undefined ? { 'query[poll_id_eq]': filters?.pollId } : ''),
          ...(filters?.pollProfileId !== undefined ? { 'query[poll_profile_id_eq]': filters?.pollProfileId } : ''),
          ...(filters?.createdAtFrom && { 'query[created_at_gteq]': filters?.createdAtFrom }),
          ...(filters?.createdAtTo && { 'query[created_at_lteq]': filters?.createdAtTo })
        }
      });
      dispatch(answer.actions.getAnswerListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      dispatch(answer.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get answers"
        })
      );
    }
  };
}
