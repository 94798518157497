// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { UserStateProps } from 'types/user';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: UserStateProps = {
  error: null,
  user: undefined,
  users: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {
    walletAddress: '',
    profileId: ''
  },
  userNft: {
    data: [],
    filters: {},
    meta: {
      count: 0,
      items: 10,
      page: 1
    }
  },
  userLootboxes: {
    data: [],
    filters: {},
    meta: {
      count: 0,
      items: 10,
      page: 1
    }
  },
  userNftOperations: {
    data: [],
    filters: {},
    meta: {
      count: 0,
      items: 10,
      page: 1
    }
  },
  userLootboxOperations: {
    data: [],
    filters: {},
    meta: {
      count: 0,
      items: 10,
      page: 1
    }
  }
};

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET USER LIST
    getUserListSuccess(state, action) {
      state.users = camelize(action.payload.users, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    },

    // GET USER NFT LIST
    getUserNftListSuccess(state, action) {
      state.userNft.data = camelize(action.payload.entities, { recursive: true });
      state.userNft.filters = action.payload.filters || {};
      state.userNft.meta = action.payload.meta;
    },

    // GET USER LOOTBOX LIST
    getUserLootboxListSuccess(state, action) {
      state.userLootboxes.data = camelize(action.payload.data.lootboxes, { recursive: true });
      state.userLootboxes.filters = action.payload.filters || {};
      state.userLootboxes.meta = action.payload.data.meta;
    },

    // GET USER NFT OPERATIONS
    getUserNftOperationsSuccess(state, action) {
      state.userNftOperations.data = camelize(action.payload.operations, { recursive: true });
      state.userNftOperations.filters = action.payload.filters || {};
      state.userNftOperations.meta = action.payload.meta;
    },

    // GET USER LOOTBOX OPERATIONS
    getUserLootboxOperationsSuccess(state, action) {
      state.userLootboxOperations.data = camelize(action.payload.operations, { recursive: true });
      state.userLootboxOperations.filters = action.payload.filters || {};
      state.userLootboxOperations.meta = action.payload.meta;
    },

    // GET User DETAIL
    getUserDetailSuccess(state, action) {
      state.user = camelize(action.payload?.user, { recursive: true });
    },

    // CLEAR User DETAIL
    clearUserDetail(state) {
      state.user = undefined;
    }
  }
});

// Reducer
export default user.reducer;

// ----------------------------------------------------------------------

export function getUserList(clientId?: number, paging?: { page?: string; perPage?: string }, filters?: any) {
  return async () => {
    try {
      const response = await axios.get(`/admins/users`, {
        params: {
          sorts: ['id desc'],
          page: paging?.page || 1,
          per_page: paging?.perPage || 10,
          'query[client_id_eq]': clientId,
          ...(filters?.wallet_address_eq !== '' ? { 'query[wallets_address_eq]': filters?.walletAddress } : ''),
          ...(filters?.wallet_address_eq !== '' ? { 'query[profile_id_eq]': filters?.profileId } : ''),
          ...(filters?.nameCont !== '' ? { 'query[name_cont]': filters?.nameCont } : ''),
          ...(filters?.emailCont !== '' ? { 'query[email_cont]': filters?.emailCont } : ''),
          ...(filters?.created_at_gteq !== '' ? { 'query[created_at_gteq]': filters?.created_at_gteq } : ''),
          ...(filters?.created_at_lteq !== '' ? { 'query[created_at_lteq]': filters?.created_at_lteq } : '')
        }
      });
      dispatch(user.actions.getUserListSuccess({ ...response.data, filters }));
    } catch (error) {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get users"
        })
      );
    }
  };
}

export function getUserNftList(
  userId: number,
  clientId?: number,
  paging?: { page?: string; perPage?: string },
  userNftFilters?: { brandId?: string; collectionId?: string; rarityId?: string }
) {
  return async () => {
    try {
      const response = await axios.get('/admins/users/' + userId + '/nft/entities', {
        params: {
          'query[collection_brand_client_id_eq]': clientId,
          page: paging?.page || 1,
          per_page: paging?.perPage || 10,
          ...(userNftFilters?.brandId !== undefined ? { 'query[collection_brand_id_eq]': userNftFilters?.brandId } : ''),
          ...(userNftFilters?.collectionId !== undefined ? { 'query[collection_id_eq]': userNftFilters?.collectionId } : ''),
          ...(userNftFilters?.rarityId !== undefined ? { 'query[rarity_id_eq]': userNftFilters?.rarityId } : '')
        }
      });
      dispatch(user.actions.getUserNftListSuccess({ ...response.data, filters: userNftFilters }));
    } catch (error) {
      // dispatch(entity.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get tokens"
        })
      );
    }
  };
}

export function getUserLootboxList(
  userId: number,
  clientId?: number,
  paging?: { page?: string; perPage?: string },
  userLootboxFilters?: { brandId?: string; rarityId?: string }
) {
  return async () => {
    try {
      const response = await axios.get('/admins/users/' + userId + '/lootboxes', {
        params: {
          'query[collection_brand_client_id_eq]': clientId,
          page: paging?.page || 1,
          per_page: paging?.perPage || 10,
          ...(userLootboxFilters?.brandId !== undefined ? { 'query[brand_id_eq]': userLootboxFilters?.brandId } : ''),
          ...(userLootboxFilters?.rarityId !== undefined ? { 'query[rarity_id_eq]': userLootboxFilters?.rarityId } : '')
        }
      });
      dispatch(user.actions.getUserLootboxListSuccess({ ...response.data, filters: userLootboxFilters }));
    } catch (error) {
      // dispatch(entity.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get tokens"
        })
      );
    }
  };
}

export function getUserNftOperations(userId: number, clientId?: number, paging?: { page?: string; perPage?: string }, filters?: {}) {
  return async () => {
    try {
      const response = await axios.get('/admins/users/' + userId + '/nft/entities/operations', {
        params: {
          sorts: ['id desc'],
          'query[collection_brand_client_id_eq]': clientId,
          page: paging?.page || 1,
          per_page: paging?.perPage || 10
          // ...(filters?.brandId !== undefined ? { 'query[collection_brand_id_eq]': filters?.brandId } : ''),
          // ...(filters?.collectionId !== undefined ? { 'query[collection_id_eq]': filters?.collectionId } : ''),
          // ...(filters?.rarityId !== undefined ? { 'query[rarity_title_eq]': filters?.rarityId } : '')
        }
      });
      dispatch(user.actions.getUserNftOperationsSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      // dispatch(entity.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get tokens"
        })
      );
    }
  };
}

export function getUserLootboxOperations(userId: number, clientId?: number, paging?: { page?: string; perPage?: string }, filters?: {}) {
  return async () => {
    try {
      const response = await axios.get('/admins/users/' + userId + '/lootboxes/operations', {
        params: {
          sorts: ['id desc'],
          'query[collection_brand_client_id_eq]': clientId,
          page: paging?.page || 1,
          per_page: paging?.perPage || 10
          // ...(filters?.brandId !== undefined ? { 'query[collection_brand_id_eq]': filters?.brandId } : ''),
          // ...(filters?.collectionId !== undefined ? { 'query[collection_id_eq]': filters?.collectionId } : ''),
          // ...(filters?.rarityId !== undefined ? { 'query[rarity_title_eq]': filters?.rarityId } : '')
        }
      });
      dispatch(user.actions.getUserLootboxOperationsSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      // dispatch(entity.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get tokens"
        })
      );
    }
  };
}

export function getUserDetail(id: number | string) {
  return async () => {
    try {
      dispatch(user.actions.clearUserDetail());

      const response = await axios.get(`/admins/users/${id}`, {});
      dispatch(user.actions.getUserDetailSuccess(response.data));
    } catch (error) {
      // dispatch(user.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get user"
        })
      );
    }
  };
}

export function addLootbox(data: any) {
  return async () => {
    try {
      await axios.post(
        `/admins/users/lootboxes`,
        {
          profile_ids: [data.profileId],
          lootbox_id: data.lootboxId,
          comment: 'REQUESTER: ' + data.requestType + ' DATA:' + data.ticketId,
          distribute_to_unique: false
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Nft added successfully'
        })
      );
    } catch (error) {
      // dispatch(promocode.actions.hasError("Error. Can't create promocode."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't add  Nft"
        })
      );
      return Promise.reject(new Error("Error. Can't add nft."));
    }
  };
}

export function addNft(data: any) {
  return async () => {
    try {
      await axios.post(
        `/admins/users/${data.userId}/nft/entities`,
        {
          entity_id: data.entityId,
          comment: 'Type: ' + data.requestType + '\nTicket id / Data:\n' + data.ticketId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Nft added successfully'
        })
      );
    } catch (error) {
      // dispatch(promocode.actions.hasError("Error. Can't create promocode."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't add  Nft"
        })
      );
      return Promise.reject(new Error("Error. Can't add nft."));
    }
  };
}
