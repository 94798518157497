// third-party
import { combineReducers } from 'redux';

// project import
import statistics from './statistics';
import events from './events';
import rchallenge from './rchallenge';

// ==============================|| COMBINE REDUCERS ||============================== //

const giver = combineReducers({
  statistics,
  events,
  rchallenge
});

export default giver;
