// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  FolderOpenOutlined,
  FireOutlined,
  BorderOutlined,
  GiftOutlined,
  ApartmentOutlined,
  QrcodeOutlined,
  FlagOutlined,
  PicRightOutlined,
  RocketOutlined
} from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  FolderOpenOutlined,
  FireOutlined,
  BorderOutlined,
  GiftOutlined,
  ApartmentOutlined,
  QrcodeOutlined,
  FlagOutlined,
  PicRightOutlined,
  RocketOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const brandSection: NavItemType = {
  id: 'brand-section',
  title: 'Brand manager section',
  type: 'group',
  children: [
    {
      id: 'collection',
      title: <FormattedMessage id="collection" />,
      type: 'item',
      url: '/collections',
      icon: icons.FolderOpenOutlined
    },
    {
      id: 'entity-page',
      title: <FormattedMessage id="entity-page" />,
      type: 'item',
      url: '/entities',
      icon: icons.FireOutlined
    },
    {
      id: 'lootbox-page',
      title: <FormattedMessage id="lootbox-page" />,
      type: 'item',
      icon: icons.GiftOutlined,
      url: '/lootboxes'
    },
    {
      id: 'promocode-page',
      title: <FormattedMessage id="promocode-page" />,
      type: 'item',
      url: '/promocodes',
      icon: icons.QrcodeOutlined,
      roles: ['super_admin']
    },
    {
      id: 'promoaction-page',
      title: <FormattedMessage id="promoaction-page" />,
      type: 'item',
      url: '/promoactions',
      icon: icons.RocketOutlined
    },
    {
      id: 'challenge-page',
      title: <FormattedMessage id="challenge-page" />,
      type: 'item',
      url: '/challenges',
      icon: icons.ApartmentOutlined
    }
    // {
    //   id: 'news-page',
    //   title: <FormattedMessage id="news-page" />,
    //   type: 'item',
    //   url: '/news',
    //   icon: icons.PicRightOutlined
    // }
  ]
};

export default brandSection;
