// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { EntityStateProps } from 'types/entity';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: EntityStateProps = {
  error: null,
  entity: undefined,
  entities: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {
    brandId: '',
    collectionId: '',
    rarityId: '',
    onlyActive: true
  }
};

const entity = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET Entity LIST
    getEntityListSuccess(state, action) {
      state.entities = camelize(action.payload.entities, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    },

    // GET Entity DETAIL
    getEntityDetailSuccess(state, action) {
      state.entity = camelize(action.payload?.data, { recursive: true });
    },

    // CLEAR Entity DETAIL
    clearEntityDetail(state) {
      state.entity = undefined;
    }
  }
});

// Reducer
export default entity.reducer;

// ----------------------------------------------------------------------

export function getEntityList(
  clientId?: number,
  paging?: { page?: string; perPage?: string },
  filters?: { brandId?: string; collectionId?: string; rarityId?: string; onlyActive?: boolean }
) {
  return async () => {
    try {
      const response = await axios.get(`/admins/nft/entities`, {
        params: {
          sorts: ['id desc'],
          'query[collection_brand_client_id_eq]': clientId,
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(Boolean(filters?.onlyActive) ? { 'query[active_eq]': true } : ''),
          ...(filters?.brandId !== undefined ? { 'query[collection_brand_id_eq]': filters?.brandId } : ''),
          ...(filters?.collectionId !== undefined ? { 'query[collection_id_eq]': filters?.collectionId } : ''),
          ...(filters?.rarityId !== undefined ? { 'query[rarity_id_eq]': filters?.rarityId } : '')
        }
      });
      dispatch(entity.actions.getEntityListSuccess({ ...response.data.data, filters: filters }));
    } catch (error) {
      // dispatch(entity.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get entities"
        })
      );
    }
  };
}

export function getEntityDetail(id: number | string) {
  return async () => {
    try {
      dispatch(entity.actions.clearEntityDetail());

      const response = await axios.get(`/admins/nft/entities/${id}`, {});
      dispatch(entity.actions.getEntityDetailSuccess(response.data));
    } catch (error) {
      // dispatch(entity.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get entity"
        })
      );
    }
  };
}

export function createEntityDetail(data: any) {
  return async () => {
    try {
      await axios.post(`/admins/nft/entities`, getEntityFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Entity created successfully'
        })
      );
    } catch (error) {
      // dispatch(entity.actions.hasError("Error. Can't create entity."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't create entity"
        })
      );
      return Promise.reject(new Error("Error. Can't create entity."));
    }
  };
}

export function updateEntityDetail(id: number, data: any) {
  return async () => {
    try {
      await axios.put(`/admins/nft/entities/${id}`, getEntityFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Entity updated successfully'
        })
      );
    } catch (error) {
      // dispatch(entity.actions.hasError("Error. Can't update entity."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update entity"
        })
      );
      return Promise.reject(new Error("Error. Can't update entity."));
    }
  };
}

function getEntityFormData(data: any): FormData {
  const formData = new FormData();

  formData.append('entity[title_en]', data.titleEn);
  formData.append('entity[title_ru]', data.titleRu);
  formData.append('entity[blockchain_title]', data.blockchainTitle);
  formData.append('entity[description_en]', data.descriptionEn);
  formData.append('entity[description_ru]', data.descriptionRu);
  formData.append('entity[blockchain_description]', data.blockchainDescription);
  formData.append('entity[collection_id]', data.collectionId);
  formData.append('entity[color]', data.color);
  formData.append('entity[rarity_id]', data.rarityId);
  formData.append('entity[preview_sharing_text_en]', data.previewSharingTextEn);
  formData.append('entity[preview_sharing_text_ru]', data.previewSharingTextRu);
  formData.append('entity[active]', data.active);
  formData.append('entity[is_transferable]', data.isTransferable);
  formData.append('entity[is_block_mintable]', data.isBlockMintable);
  formData.append('entity[is_exchangeable]', data.isExchangeable);
  formData.append('entity[price]', data.price);
  if (data.fixedSupply) {
    formData.append('entity[fixed_supply]', data.fixedSupply);
  }
  if (data.supplyAmount) {
    formData.append('entity[supply_amount]', data.supplyAmount);
  }
  if (data.previewImage instanceof File) {
    formData.append('entity[preview_image]', data.previewImage);
  }
  if (data.previewVideo instanceof File) {
    formData.append('entity[preview_video]', data.previewVideo);
  }
  if (data.previewSharingVideo instanceof File) {
    formData.append('entity[preview_sharing_video]', data.previewSharingVideo);
  } else if (data.previewSharingVideo === null) {
    formData.append('entity[remove_preview_sharing_video]', 'true');
  }
  if (data.modelFile instanceof File) {
    formData.append('entity[model_file]', data.modelFile);
  }
  if (data.modelFileMeta instanceof File) {
    formData.append('entity[model_file_meta]', data.modelFileMeta);
  } else if (data.modelFileMeta === null) {
    formData.append('entity[remove_model_file_meta]', 'true');
  }

  return formData;
}
