// third-party
import { combineReducers } from 'redux';

// project import
import posts from './posts';
import postLikes from './post-likes';
import postComments from './post-comments';
import followings from './followings';
import storyLikes from './story-likes';
import stories from './stories';
import answers from './answers';

// ==============================|| COMBINE REDUCERS ||============================== //

const events = combineReducers({
  posts,
  postLikes,
  postComments,
  followings,
  storyLikes,
  stories,
  answers
});

export default events;
