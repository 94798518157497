// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { SettingsStateProps } from 'types/settings';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: SettingsStateProps = {
  error: null,
  settings: undefined
};

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET SETTINGS
    getSettingsSuccess(state, action) {
      // let settingsUpdated = {};
      const settingsUpdated = action.payload.settings.reduce(
        (acc: { [key: string]: any }, current: { key: string; value: any }, index: number) => {
          acc[`${current.key}`] = current.value;
          return acc;
        },
        {}
      );
      state.settings = camelize(settingsUpdated, { recursive: true });
    }
  }
});

// Reducer
export default settings.reducer;

// ----------------------------------------------------------------------

export function getSettings() {
  return async () => {
    try {
      const response = await axios.get(`/admins/app_setting`, {});
      dispatch(settings.actions.getSettingsSuccess(response.data));
    } catch (error) {
      // dispatch(settings.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get settings"
        })
      );
    }
  };
}

export function updateSettings(data: any) {
  return async () => {
    try {
      await axios.patch(`/admins/app_setting`, getSettingsFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(getSettings());
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Settings updated successfully'
        })
      );
    } catch (error) {
      // dispatch(settings.actions.hasError("Error. Can't update settings."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update settings"
        })
      );
      return Promise.reject(new Error("Error. Can't update settings."));
    }
  };
}

function getSettingsFormData(data: any): FormData {
  const formData = new FormData();

  if (data.promoCodeExpires !== undefined) {
    formData.append('settings[promo_code_expires]', data.promoCodeExpires);
  }
  if (data.defaultRelayType !== undefined) {
    formData.append('settings[default_relay_type]', data.defaultRelayType);
  }
  if (data.defenderConfig.maxGasPrice !== undefined) {
    formData.append('settings[defender_config][max_gas_price]', data.defenderConfig.maxGasPrice);
  }
  if (data.defenderConfig.maxEmptyWalletsAmount !== undefined) {
    formData.append('settings[defender_config][max_empty_wallets_amount]', data.defenderConfig.maxEmptyWalletsAmount);
  }
  if (data.defenderConfig.minBalance !== undefined) {
    formData.append('settings[defender_config][min_balance]', data.defenderConfig.minBalance);
  }
  if (data.defenderConfig.depositAmount !== undefined) {
    formData.append('settings[defender_config][deposit_amount]', data.defenderConfig.depositAmount);
  }
  if (data.itxConfig.maxGasPrice !== undefined) {
    formData.append('settings[itx_config][max_gas_price]', data.itxConfig.maxGasPrice);
  }
  if (data.itxConfig.minEmptyWalletsAmount !== undefined) {
    formData.append('settings[itx_config][min_empty_wallets_amount]', data.itxConfig.minEmptyWalletsAmount);
  }
  if (data.itxConfig.newEmptyWalletsAmount !== undefined) {
    formData.append('settings[itx_config][new_empty_wallets_amount]', data.itxConfig.newEmptyWalletsAmount);
  }
  if (data.lootConfig.minEmptyWalletsAmount !== undefined) {
    formData.append('settings[loot_config][min_empty_wallets_amount]', data.lootConfig.minEmptyWalletsAmount);
  }
  if (data.lootConfig.newEmptyWalletsAmount !== undefined) {
    formData.append('settings[loot_config][new_empty_wallets_amount]', data.lootConfig.newEmptyWalletsAmount);
  }

  return formData;
}
