// third-party
// import { FormattedMessage } from 'react-intl';

// assets
import { HistoryOutlined, TeamOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

const icons = {
  HistoryOutlined,
  TeamOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const supportSection: NavItemType = {
  id: 'support-section',
  title: 'Support section',
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'Users',
      type: 'item',
      url: '/users',
      icon: icons.TeamOutlined
    }
  ]
};

export default supportSection;
