// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import Locales from 'components/Locales';
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from 'components/ScrollTop';
import Snackbar from 'components/@extended/Snackbar';

// auth provider
import { LootboxAuthProvider } from 'contexts/LootboxAuthContext';
import { GiverAuthProvider } from 'contexts/GiverAuthContext';

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <ThemeCustomization>
    {/* <RTLLayout> */}
    <Locales>
      <ScrollTop>
        <LootboxAuthProvider>
          <GiverAuthProvider>
            <>
              <Routes />
              <Snackbar />
            </>
          </GiverAuthProvider>
        </LootboxAuthProvider>
      </ScrollTop>
    </Locales>
    {/* </RTLLayout> */}
  </ThemeCustomization>
);

export default App;
