// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { CollectionStateProps } from 'types/collection';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: CollectionStateProps = {
  error: null,
  collection: undefined,
  collections: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {
    brandId: '',
    onlyActive: true
  }
};

const collection = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET COLLECTION LIST
    getCollectionListSuccess(state, action) {
      state.collections = camelize(action.payload.collections, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    },

    // GET COLLECTION DETAIL
    getCollectionDetailSuccess(state, action) {
      state.collection = camelize(action.payload?.data, { recursive: true });
    }
  }
});

// Reducer
export default collection.reducer;

// ----------------------------------------------------------------------

export function getCollectionList(
  clientId?: number,
  paging?: { page?: string; perPage?: string },
  filters?: { brandId?: string; onlyActive?: boolean }
) {
  return async () => {
    try {
      const response = await axios.get(`/admins/nft/collections`, {
        params: {
          sorts: ['id desc'],
          'query[brand_client_id_eq]': clientId,
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(Boolean(filters?.onlyActive) ? { 'query[active_eq]': true } : ''),
          ...(filters?.brandId !== undefined ? { 'query[brand_id_eq]': filters?.brandId } : '')
        }
      });
      dispatch(collection.actions.getCollectionListSuccess({ ...response.data.data, filters: filters }));
    } catch (error) {
      // dispatch(collection.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get collections"
        })
      );
    }
  };
}

export function getCollectionDetail(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/admins/nft/collections/${id}`, {});
      dispatch(collection.actions.getCollectionDetailSuccess(response.data));
    } catch (error) {
      // dispatch(collection.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get collection"
        })
      );
    }
  };
}

export function createCollectionDetail(data: any) {
  return async () => {
    try {
      await axios.post(`/admins/nft/collections`, getCollectionFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Collection created successfully'
        })
      );
    } catch (error) {
      // dispatch(collection.actions.hasError("Error. Can't create collection."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't create collection"
        })
      );
      return Promise.reject(new Error("Error. Can't create collection."));
    }
  };
}

export function updateCollectionDetail(id: number, data: any) {
  return async () => {
    try {
      await axios.put(`/admins/nft/collections/${id}`, getCollectionFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Collection updated successfully'
        })
      );
    } catch (error) {
      // dispatch(collection.actions.hasError("Error. Can't update collection."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update collection"
        })
      );
      return Promise.reject(new Error("Error. Can't update collection."));
    }
  };
}

function getCollectionFormData(data: any): FormData {
  const formData = new FormData();

  formData.append('collection[active]', data.active);
  formData.append('collection[title_en]', data.titleEn);
  formData.append('collection[title_ru]', data.titleRu);
  formData.append('collection[brand_id]', data.brandId);
  formData.append('collection[relay_type]', data.relayType);
  if (data.image instanceof File) {
    formData.append('collection[image]', data.image);
  }

  return formData;
}
