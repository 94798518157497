// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../../../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { BrandStatisticsStateProps } from 'types/giver/statistics';
import { giverApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: BrandStatisticsStateProps = {
  error: null,
  brandStatistics: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {}
};

const brands = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CHALLENGE LIST
    getBrandStatisticsSuccess(state, action) {
      state.brandStatistics = camelize(action.payload.data, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    }
  }
});

// Reducer
export default brands.reducer;

// ----------------------------------------------------------------------

export function getBrandStatistics(profileId: string, filters?: { brandId?: string }) {
  return async () => {
    try {
      const response = await axios.get(`/admin/statistic/brands/` + profileId, {
        params: {
          // ...(paging?.page && { page: paging?.page }),
          // ...(paging?.perPage && { per_page: paging?.perPage }),
          // ...(filters?.brandId !== undefined ? { 'query[lootbox_brand_id_eq]': filters?.brandId } : '')
        }
      });
      dispatch(brands.actions.getBrandStatisticsSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      dispatch(brands.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get brand statistics"
        })
      );
    }
  };
}
