// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { ClientStateProps } from 'types/clients';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: ClientStateProps = {
  error: null,
  selectedClientId: Number(localStorage.getItem('selected')) || 2,
  client: undefined,
  clients: [],
  meta: {
    count: 0,
    items: 0,
    page: 1
  }
};

const clients = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // SET SELECTED CLIENT
    setSelectedClient(state, action) {
      state.selectedClientId = action.payload.id;
    },

    getClientListSuccess(state, action) {
      state.clients = action.payload.clients;
    }
  }
});

// Reducer
export default clients.reducer;

// ----------------------------------------------------------------------

export function setCurrentClient(id: number) {
  return async () => {
    localStorage.setItem('selected', id.toString());
    dispatch(clients.actions.setSelectedClient({ id }));
  };
}

export function getClientList() {
  return async () => {
    try {
      const response = await axios.get(`/admins/clients`, {});
      dispatch(clients.actions.getClientListSuccess(response.data));
    } catch (error) {
      dispatch(clients.actions.hasError('error'));
    }
  };
}
