// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { PromoactionStateProps } from 'types/promoaction';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: PromoactionStateProps = {
  error: null,
  promoaction: undefined,
  promoactions: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {
    lootboxId: '',
    onlyActive: true,
    onlyEmpty: false
  }
};

const promoaction = createSlice({
  name: 'promoaction',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET PROMOCODE LIST
    getPromoactionListSuccess(state, action) {
      state.promoactions = camelize(action.payload.data.promo_actions, { recursive: true });
      state.meta = action.payload.data.meta;
      state.filters = action.payload.filters;
    },

    // GET PROMOCODE DETAIL
    getPromoactionDetailSuccess(state, action) {
      state.promoaction = camelize(action.payload.data, { recursive: true });
    }
  }
});

// Reducer
export default promoaction.reducer;

// ----------------------------------------------------------------------

export function getPromoactionList(
  clientId?: number,
  paging?: { page?: string; perPage?: string },
  filters?: { lootboxId?: string; onlyActive?: boolean; onlyEmpty?: boolean }
) {
  return async () => {
    try {
      const response = await axios.get(`/admins/promo_actions`, {
        params: {
          sorts: ['created_at desc'],
          // 'query[lootbox_brand_client_id_eq]': clientId,
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(filters?.lootboxId !== undefined ? { 'query[lootbox_id_eq]': filters?.lootboxId } : ''),
          ...(Boolean(filters?.onlyActive) ? { 'query[active_eq]': true } : ''),
          ...(Boolean(filters?.onlyEmpty) ? { 'query[unused_eq]': 0 } : '')
        }
      });
      dispatch(promoaction.actions.getPromoactionListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      // dispatch(promoaction.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get promoactions"
        })
      );
    }
  };
}

export function getPromoactionDetail(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/admins/promo_actions/${id}`, {});
      dispatch(promoaction.actions.getPromoactionDetailSuccess(response.data));
    } catch (error) {
      // dispatch(promoaction.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get promoaction"
        })
      );
    }
  };
}

export function updatePromoactionDetail(id: string, data: any) {
  return async () => {
    try {
      await axios.put(`/admins/promo_actions/${id}`, getPromoactionFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Promoaction updated successfully'
        })
      );
    } catch (error) {
      // dispatch(promoaction.actions.hasError("Error. Can't update promoaction."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update promoaction."
        })
      );
      return Promise.reject(new Error("Error. Can't update promoaction"));
    }
  };
}

export function deletePromoaction(itemIds: number[], clientId?: number, paging?: {}, filters?: {}) {
  return async () => {
    try {
      await axios.delete(`/admins/promo_actions`, {
        data: {
          ids: itemIds
        }
      });
      dispatch(getPromoactionList(clientId, paging, filters));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Promoaction deleted successfully'
        })
      );
    } catch (error) {
      // dispatch(promoaction.actions.hasError("Error. Can't delete promoaction."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't delete promoaction"
        })
      );
      return Promise.reject(new Error("Error. Can't delete promoaction."));
    }
  };
}

function getPromoactionFormData(data: any): FormData {
  const formData = new FormData();

  formData.append('promo_action[title]', data.title);
  formData.append('promo_action[message_template]', data.messageTemplate || '');
  formData.append('promo_action[send_message]', data.sendMessage);
  formData.append('promo_action[active]', data.active);

  return formData;
}

export function uploadPromoactionCodes(id: string, data: any) {
  const codesArray = data.codes
    .replace(/ /g, '')
    .split('\n')
    .filter((code: string) => code !== '');

  return async () => {
    try {
      await axios.post(
        `/admins/promo_actions/${id}/codes`,
        {
          promo_action: {
            codes: codesArray
          }
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Codes uploaded successfully'
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't upload codes"
        })
      );
      return Promise.reject(new Error("Error. Can't create promoaction."));
    }
  };
}
