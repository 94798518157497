// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { BlockchainStateProps } from 'types/blockchain';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: BlockchainStateProps = {
  error: null,
  blockchain: {
    relayers: {
      itx: {
        maxGasPrice: 0,
        collections: [],
        pendingTransactionsAmount: 0,
        inProcessTransactionsAmount: 0,
        failedTransactionsAmount: 0
      },
      defender: {
        maxGasPrice: 0,
        collections: [],
        distributionWallets: [],
        pendingTransactionsAmount: 0,
        inProcessTransactionsAmount: 0,
        failedTransactionsAmount: 0
      }
    },
    wallets: {
      emptyAmount: 0,
      relayers: {
        defender: {
          creationBlocked: false
        }
      }
    }
  },
  walletsStatistic: {
    users: {
      amount: 0
    },
    wallets: {
      amount: 0
    }
  }
};

const blockchain = createSlice({
  name: 'blockchain',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET BLOCKCHAIN RTD
    getBlockchainRtdSuccess(state, action) {
      state.blockchain = camelize(action.payload, { recursive: true });
    },

    // GET WALLET STATISTIC
    getWalletStatisticSuccess(state, action) {
      state.walletsStatistic = camelize(action.payload, { recursive: true });
    }
  }
});

// Reducer
export default blockchain.reducer;

// ----------------------------------------------------------------------

export function getBlockchainRtd() {
  return async () => {
    try {
      const response = await axios.get(`/admins/blockchain_rtd`, {});
      dispatch(blockchain.actions.getBlockchainRtdSuccess(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get blockchain data"
        })
      );
    }
  };
}

export function refillBalance(amount: any) {
  const formData = new FormData();
  formData.append('balance', amount);

  return async () => {
    try {
      await axios.put(`/admins/blockchain_rtd`, formData, {});
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Refill request sent successfully'
        })
      );
    } catch (error) {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't refill"
        })
      );
      return Promise.reject(new Error("Error. Can't refill"));
    }
  };
}

export function getWalletStatistic(data: any) {
  return async () => {
    try {
      const response = await axios.get(`/admins/statistic`, {
        params: {
          'users_query[created_at_gteq]': data.from,
          'wallets_query[created_at_gteq]': data.from,
          'users_query[created_at_lteq]': data.to,
          'wallets_query[created_at_lteq]': data.to
        }
      });
      dispatch(blockchain.actions.getWalletStatisticSuccess(response.data));
    } catch (error) {
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get wallet statistic"
        })
      );
    }
  };
}
