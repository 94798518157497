import { useNavigate } from 'react-router-dom';

// material-ui
import { Box, IconButton, Chip } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';

// third party
import { AlertOutlined, SettingOutlined } from '@ant-design/icons';

// project import
import Profile from './Profile';
import ClientSelect from './ClientSelect';
import useAuth from 'hooks/useAuth';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const giverLoggedIn = useAuth('giver').isLoggedIn;
  const lootboxLoggedIn = useAuth('lootbox').isLoggedIn;
  const role = useAuth('lootbox')?.user?.role as string;
  const history = useNavigate();
  return (
    <>
      <Box sx={{ width: '100%', ml: 1 }} />
      <ClientSelect />
      <Box sx={{ flexShrink: 0, ml: 1 }}>
        <IconButton
          sx={{ color: 'text.primary', bgcolor: 'grey.200' }}
          aria-label="blockchain"
          onClick={() => {
            history(`/blockchain`);
          }}
          href={`/blockchain`}
          disabled={role !== 'super_admin'}
        >
          <AlertOutlined />
        </IconButton>
        <IconButton
          sx={{ color: 'text.primary', bgcolor: 'grey.200', ml: 1 }}
          aria-label="settings"
          onClick={() => {
            history(`/settings`);
          }}
          href={`/settings`}
          disabled={role !== 'super_admin'}
        >
          <SettingOutlined />
        </IconButton>
        <Chip
          sx={{ ml: 1 }}
          variant="outlined"
          icon={lootboxLoggedIn ? <LockOpenIcon /> : <LockIcon />}
          label="Lootbox"
          onClick={lootboxLoggedIn ? undefined : () => history(`/login?api=lootbox&lastPath=` + window.location.pathname)}
          color={lootboxLoggedIn ? 'success' : 'error'}
        />
        <Chip
          sx={{ ml: 1 }}
          variant="outlined"
          icon={giverLoggedIn ? <LockOpenIcon /> : <LockIcon />}
          label="Giver"
          onClick={giverLoggedIn ? undefined : () => history(`/login?api=giver&lastPath=` + window.location.pathname)}
          color={giverLoggedIn ? 'success' : 'secondary'}
        />
      </Box>
      <Profile />
      {/* {!matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {!matchesXs && <ClientSelect />}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />} */}
    </>
  );
};

export default HeaderContent;
