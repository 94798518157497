// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../../../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { StoriesStateProps } from 'types/giver/events';
import { giverApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: StoriesStateProps = {
  error: null,
  storyList: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {}
};

const stories = createSlice({
  name: 'stories',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CHALLENGE LIST
    getStoryListSuccess(state, action) {
      state.storyList = camelize(action.payload.data, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    }
  }
});

// Reducer
export default stories.reducer;

// ----------------------------------------------------------------------

export function getStoryList(
  filters?: {
    profileId?: number;
    deleted?: string;
    mentionProfileIds?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
  },
  paging?: { page?: string; perPage?: string }
) {
  return async () => {
    try {
      const response = await axios.get(`/admin/events/story`, {
        params: {
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(filters?.profileId !== undefined ? { 'query[profile_id_eq]': filters?.profileId } : ''),
          ...(filters?.deleted !== undefined ? { 'query[deleted_eq]': filters?.deleted } : ''),
          ...(filters?.mentionProfileIds !== undefined ? { 'query[mention_profile_ids_eq]': filters?.mentionProfileIds } : ''),
          ...(filters?.createdAtFrom && { 'query[creation_time_gteq]': filters?.createdAtFrom }),
          ...(filters?.createdAtTo && { 'query[creation_time_lteq]': filters?.createdAtTo })
        }
      });
      dispatch(stories.actions.getStoryListSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      dispatch(stories.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get stories"
        })
      );
    }
  };
}
