// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import snackbar from './snackbar';
import lootbox from './lootbox';
import admin from './admin';
import brand from './brand';
import challenge from './challenge';
import promocode from './promocode';
import promoaction from './promoaction';
import collection from './collection';
import entity from './entity';
import client from './client';
import user from './user';
import blockchain from './blockchain';
import settings from './settings';
import giver from './giver';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  lootbox,
  admin,
  brand,
  challenge,
  promocode,
  promoaction,
  collection,
  entity,
  client,
  user,
  blockchain,
  settings,
  giver
});

export default reducers;
