// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { LootboxStateProps } from 'types/lootbox';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: LootboxStateProps = {
  error: null,
  lootbox: undefined,
  lootboxes: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {
    brandId: '',
    rarityId: '',
    onlyActive: true
  }
};

const lootbox = createSlice({
  name: 'lootbox',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET LOOTBOX LIST
    getLootBoxListSuccess(state, action) {
      state.lootboxes = camelize(action.payload.lootboxes, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    },

    // GET LOOTBOX DETAIL
    getLootBoxDetailSuccess(state, action) {
      state.lootbox = camelize(action.payload?.data, { recursive: true });
    }
  }
});

// Reducer
export default lootbox.reducer;

// ----------------------------------------------------------------------

export function getLootBoxList(
  clientId?: number,
  paging?: { page?: string; perPage?: string },
  filters?: { brandId?: string; rarityId?: string; onlyActive?: boolean }
) {
  return async () => {
    try {
      const response = await axios.get(`/admins/lootboxes`, {
        params: {
          sorts: ['id desc'],
          'query[brand_client_id_eq]': clientId,
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(Boolean(filters?.onlyActive) ? { 'query[active_eq]': true } : ''),
          ...(filters?.brandId && { 'query[brand_id_eq]': filters?.brandId }),
          ...(filters?.rarityId && { 'query[rarity_id_eq]': filters?.rarityId })
        }
      });
      dispatch(lootbox.actions.getLootBoxListSuccess({ ...response.data.data, filters }));
    } catch (error) {
      // dispatch(lootbox.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get lootboxes"
        })
      );
    }
  };
}

export function clearLootBoxDetail() {
  return async () => {
    dispatch(lootbox.actions.getLootBoxDetailSuccess({}));
  };
}

export function getLootBoxDetail(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/admins/lootboxes/${id}`, {});
      dispatch(lootbox.actions.getLootBoxDetailSuccess(response.data));
    } catch (error) {
      // dispatch(lootbox.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get lootbox"
        })
      );
    }
  };
}

export function createLootBoxDetail(data: any) {
  return async () => {
    try {
      await axios.post(`/admins/lootboxes`, getLootboxFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Lootbox created successfully'
        })
      );
    } catch (error) {
      // dispatch(lootbox.actions.hasError("Error. Can't create lootbox."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't create lootbox"
        })
      );
      return Promise.reject(new Error("Error. Can't create lootbox."));
    }
  };
}

export function updateLootBoxDetail(id: number, data: any) {
  return async () => {
    try {
      await axios.put(`/admins/lootboxes/${id}`, getLootboxFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(getLootBoxDetail(id.toString()));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Lootbox updated successfully'
        })
      );
    } catch (error) {
      // dispatch(lootbox.actions.hasError("Error. Can't update lootbox."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update lootbox"
        })
      );
      return Promise.reject(new Error("Error. Can't update lootbox."));
    }
  };
}

export function deleteLootbox(itemIds: string[], clientId?: number, paging?: {}, filters?: {}) {
  return async () => {
    try {
      await axios.delete(`/admins/lootboxes/${itemIds[0]}`, {});
      dispatch(getLootBoxList(clientId, paging, filters));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Lootbox deleted successfully'
        })
      );
    } catch (error) {
      // dispatch(lootbox.actions.hasError("Error. Can't delete lootbox."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't delete lootbox"
        })
      );
      return Promise.reject(new Error("Error. Can't delete lootbox."));
    }
  };
}

function getLootboxFormData(data: any): FormData {
  const formData = new FormData();

  formData.append('lootbox[title_en]', data.titleEn);
  formData.append('lootbox[title_ru]', data.titleRu);
  formData.append('lootbox[rarity_id]', data.rarityId);
  formData.append('lootbox[brand_id]', data.brandId);
  formData.append('lootbox[active]', data.active);
  formData.append('lootbox[is_complex_unboxed]', data.isComplexUnboxed);
  formData.append('lootbox[supply_amount]', data.supplyAmount || '');
  if (data.previewImage instanceof File) {
    formData.append('lootbox[preview_image]', data.previewImage);
  }
  if (data.modelFile instanceof File) {
    formData.append('lootbox[model_file]', data.modelFile);
  }
  (data.lbxNft || []).forEach((item: any, index: number) => {
    if (item.destroy) {
      formData.append(`lootbox[lootbox_entities_attributes][${index}][id]`, item.lootboxEntityId);
      formData.append(`lootbox[lootbox_entities_attributes][${index}][_destroy]`, item.destroy);
    } else if (item.lootboxEntityId) {
      formData.append(`lootbox[lootbox_entities_attributes][${index}][id]`, item.lootboxEntityId);
      formData.append(`lootbox[lootbox_entities_attributes][${index}][entity_id]`, item.id);
      formData.append(`lootbox[lootbox_entities_attributes][${index}][chance_percentage]`, item.chancePercentage);
    } else {
      formData.append(`lootbox[lootbox_entities_attributes][${index}][entity_id]`, item.id);
      formData.append(`lootbox[lootbox_entities_attributes][${index}][chance_percentage]`, item.chancePercentage);
      item.promoAction && formData.append(`lootbox[lootbox_entities_attributes][${index}][promo_action]`, item.promoAction);
    }
  });

  return formData;
}
