// material-ui

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */
    <svg width="35" height="35" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <radialGradient
          gradientTransform="translate(293.709 333.843) rotate(90) scale(255.857 316.74)"
          gradientUnits="userSpaceOnUse"
          r="1"
          cy="0"
          cx="0"
          id="paint1_radial_401_14615"
        >
          <stop stopColor="white" />
          <stop stopOpacity="0" stopColor="white" offset="1" />
        </radialGradient>
      </defs>
      <g>
        <title>background</title>
        <rect fill="none" id="canvas_background" height="37" width="37" y="-1" x="-1" />
      </g>
      <g>
        <title>Layer 1</title>
        <g stroke="null" id="svg_2">
          <path
            stroke="null"
            strokeWidth="0"
            id="svg_1"
            fill="#BE37FD"
            d="m14.80363,1.02147c1.60205,-0.93799 3.57585,-0.93799 5.17791,0l10.27388,6.01519c1.60211,0.93803 2.58901,2.67147 2.58901,4.54746l0,12.03039c0,1.87599 -0.9869,3.60944 -2.58901,4.54746l-10.27388,6.0152c-1.60206,0.93797 -3.57586,0.93797 -5.17791,0l-10.27391,-6.0152c-1.60207,-0.93802 -2.58898,-2.67147 -2.58898,-4.54746l0,-12.03039c0,-1.87599 0.98691,-3.60943 2.58898,-4.54746l10.27391,-6.01519z"
          />
          <path
            stroke="null"
            strokeWidth="0"
            id="svg_3"
            fill="url(#paint1_radial_401_14615)"
            d="m13.31303,16.25631c0.49218,0.32158 1.08466,0.61741 1.53308,0.99362c0.62583,0.52499 1.15353,1.08438 1.47031,1.64811c0.14921,0.26545 0.28376,0.73714 0.39888,1.38374c0.11395,0.64 0.20544,1.43011 0.27824,2.31656c0.14554,1.77257 0.13965,3.91739 0.16795,5.99153l0,0.86117l0,4.66618c0,0.15355 0.12303,0.27806 0.27479,0.27806c0.15176,0 0.27479,-0.12451 0.27479,-0.27806l0,-4.66618l0,-0.86117c0.02825,-2.07414 0.06563,-4.21895 0.21118,-5.99153c0.07285,-0.88645 0.16429,-1.67656 0.27824,-2.31656c0.11512,-0.6466 0.24967,-1.1183 0.39888,-1.38374c0.31679,-0.56374 0.84454,-1.12313 1.47031,-1.64811c0.62397,-0.52349 1.33204,-0.87448 2.05833,-1.26025l0.74657,-0.39645l9.28858,-4.93291c0.13439,-0.07133 0.18617,-0.23942 0.11565,-0.37541c-0.07057,-0.13594 -0.23666,-0.18832 -0.37106,-0.117l-9.35447,4.9679l-0.37222,0.19766c-0.47264,0.24527 -0.98573,0.50551 -1.42311,0.71675c-0.78369,0.37847 -1.49543,0.69211 -2.09189,0.91071c-0.60262,0.2209 -1.06745,0.33699 -1.36582,0.33699c-0.29704,0 -0.75677,-0.1192 -1.35174,-0.34675c-0.58871,-0.22519 -0.94224,-0.32427 -2.05956,-0.99556l-0.3907,-0.23475l-10.32054,-6.20078c-0.13049,-0.07841 -0.29909,-0.03488 -0.37657,0.09714c-0.07748,0.13203 -0.0345,0.30264 0.09599,0.38105l10.25656,6.1623l0.15935,0.09575z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoIcon;
