// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { ChallengeStateProps } from 'types/challenge';
import { lootboxApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: ChallengeStateProps = {
  error: null,
  challenge: undefined,
  challenges: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {
    brandId: '',
    onlyActive: true
  }
};

const challenge = createSlice({
  name: 'challenge',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CHALLENGE LIST
    getChallengeListSuccess(state, action) {
      state.challenges = camelize(action.payload.challenges, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    },

    // GET CHALLENGE DETAIL
    getChallengeDetailSuccess(state, action) {
      state.challenge = camelize(action.payload.data, { recursive: true });
    }
  }
});

// Reducer
export default challenge.reducer;

// ----------------------------------------------------------------------

export function getChallengeList(
  clientId?: number,
  paging?: { page?: string; perPage?: string },
  filters?: { brandId?: string; onlyActive?: boolean }
) {
  return async () => {
    try {
      const response = await axios.get(`/admins/challenges`, {
        params: {
          sorts: ['id desc'],
          'query[brand_client_id_eq]': clientId,
          ...(paging?.page && { page: paging?.page }),
          ...(paging?.perPage && { per_page: paging?.perPage }),
          ...(Boolean(filters?.onlyActive) ? { 'query[active_eq]': true } : ''),
          ...(filters?.brandId !== undefined ? { 'query[lootbox_brand_id_eq]': filters?.brandId } : '')
        }
      });
      dispatch(challenge.actions.getChallengeListSuccess({ ...response.data.data, filters: filters }));
    } catch (error) {
      // dispatch(challenge.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get challenges"
        })
      );
    }
  };
}

export function getChallengeDetail(id: string) {
  return async () => {
    try {
      const response = await axios.get(`/admins/challenges/${id}`, {});
      dispatch(challenge.actions.getChallengeDetailSuccess(response.data));
    } catch (error) {
      // dispatch(challenge.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get challenge"
        })
      );
    }
  };
}

export function createChallengeDetail(data: any) {
  return async () => {
    try {
      await axios.post(`/admins/challenges`, getChallengeFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Challenge created successfully'
        })
      );
    } catch (error) {
      // dispatch(challenge.actions.hasError("Error. Can't create challenge."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't create challenge"
        })
      );
      return Promise.reject(new Error("Error. Can't create challenge."));
    }
  };
}

export function updateChallengeDetail(id: number, data: any) {
  return async () => {
    try {
      await axios.put(`/admins/challenges/${id}`, getChallengeFormData(data), {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(getChallengeDetail(id.toString()));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Challenge updated successfully'
        })
      );
    } catch (error) {
      // dispatch(challenge.actions.hasError("Error. Can't update challenge."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't update challenge"
        })
      );
      return Promise.reject(new Error("Error. Can't update challenge."));
    }
  };
}

export function deleteChallenge(itemIds: string[], clientId?: number, paging?: {}, filters?: {}) {
  return async () => {
    try {
      await axios.delete(`/admins/challenges/${itemIds[0]}`, {});
      dispatch(getChallengeList(clientId, paging, filters));
      dispatch(
        openSnackbar({
          severity: 'success',
          message: 'Challenge deleted successfully'
        })
      );
    } catch (error) {
      // dispatch(challenge.actions.hasError("Error. Can't delete challenge."));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't delete challenge"
        })
      );
      return Promise.reject(new Error("Error. Can't delete challenge."));
    }
  };
}

function getChallengeFormData(data: any): FormData {
  const formData = new FormData();

  formData.append('challenge[active]', data.active);
  formData.append('challenge[is_available_only_once]', data.isAvailableOnlyOnce);
  formData.append('challenge[is_progress_burned]', data.isProgressBurned);
  formData.append('challenge[title_en]', data.titleEn);
  formData.append('challenge[title_ru]', data.titleRu);
  formData.append('challenge[sub_title_en]', data.subTitleEn || '');
  formData.append('challenge[sub_title_ru]', data.subTitleRu || '');
  formData.append('challenge[list_title_en]', data.listTitleEn || '');
  formData.append('challenge[list_title_ru]', data.listTitleRu || '');
  formData.append('challenge[progress_bar_title_en]', data.progressBarTitleEn || '');
  formData.append('challenge[progress_bar_title_ru]', data.progressBarTitleRu || '');
  formData.append('challenge[short_description_en]', data.shortDescriptionEn);
  formData.append('challenge[short_description_ru]', data.shortDescriptionRu);
  formData.append('challenge[description_en]', data.descriptionEn || '');
  formData.append('challenge[description_ru]', data.descriptionRu || '');
  formData.append('challenge[participation_rules_en]', data.participationRulesEn || '');
  formData.append('challenge[participation_rules_ru]', data.participationRulesRu || '');
  formData.append('challenge[brand_id]', data.brandId);
  formData.append('challenge[lootbox_id]', data.lootboxId);
  formData.append('challenge[start_at]', data.startAt || '');
  formData.append('challenge[end_at]', data.endAt || '');
  if (data.video instanceof File) {
    formData.append('challenge[video]', data.video);
  } else if (data.video === null) {
    formData.append('challenge[remove_video]', 'true');
  }
  if (data.previewImage instanceof File) {
    formData.append('challenge[preview_image]', data.previewImage);
  }
  if (data.backgroundImage instanceof File) {
    formData.append('challenge[background_image]', data.backgroundImage);
  } else if (data.backgroundImage === null) {
    formData.append('challenge[remove_background_image]', 'true');
  }
  if (data.links.length !== 0) {
    data.links.forEach((item: any, index: number) => {
      formData.append(`challenge[links][][title_en]`, item.titleEn);
      formData.append(`challenge[links][][title_ru]`, item.titleRu);
      formData.append(`challenge[links][][url]`, item.url);
      formData.append(`challenge[links][][type]`, item.type);
    });
  } else {
    formData.append('challenge[links][]', '');
  }
  const addedEntities = [...data.pasNft, ...data.partNft, ...data.memNft];
  (addedEntities || []).forEach((item: any, index: number) => {
    if (item.destroy) {
      formData.append(`challenge[challenge_nft_entities_attributes][${index}][id]`, item.challengeEntityId);
      formData.append(`challenge[challenge_nft_entities_attributes][${index}][_destroy]`, item.destroy);
    } else if (item.challengeEntityId) {
      formData.append(`challenge[challenge_nft_entities_attributes][${index}][id]`, item.challengeEntityId);
      formData.append(`challenge[challenge_nft_entities_attributes][${index}][entity_id]`, item.id);
      formData.append(`challenge[challenge_nft_entities_attributes][${index}][amount]`, item.amount);
    } else {
      formData.append(`challenge[challenge_nft_entities_attributes][${index}][entity_id]`, item.id);
      formData.append(`challenge[challenge_nft_entities_attributes][${index}][amount]`, item.amount);
      formData.append(`challenge[challenge_nft_entities_attributes][${index}][usage_type]`, item.usageType);
    }
  });

  return formData;
}
