// project import
import adminSection from './admin';
import brandSection from './brand';
import giverSection from './giver';
import supportSection from './support';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [adminSection, brandSection, giverSection, supportSection]
};

export default menuItems;
