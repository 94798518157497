// third-party
import { createSlice } from '@reduxjs/toolkit';
import { camelize } from '@ridi/object-case-converter';

// project imports
import { dispatch } from '../../../index';
import { openSnackbar } from 'store/reducers/snackbar';
import { RegularStatisticsStateProps } from 'types/giver/statistics';
import { giverApi as axios } from 'utils/axios';

// ----------------------------------------------------------------------

export const initialState: RegularStatisticsStateProps = {
  error: null,
  regularStatistics: [],
  meta: {
    count: 0,
    items: 10,
    page: 1
  },
  filters: {}
};

const regular = createSlice({
  name: 'regular',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CHALLENGE LIST
    getRegularStatisticsSuccess(state, action) {
      state.regularStatistics = camelize(action.payload.data, { recursive: true });
      state.meta = action.payload.meta;
      state.filters = action.payload.filters;
    }
  }
});

// Reducer
export default regular.reducer;

// ----------------------------------------------------------------------

export function getRegularStatistics(profileId: string, filters?: { brandId?: string }) {
  return async () => {
    try {
      const response = await axios.get(`/admin/statistic/regular/` + profileId, {
        params: {
          // ...(paging?.page && { page: paging?.page }),
          // ...(paging?.perPage && { per_page: paging?.perPage }),
          // ...(filters?.brandId !== undefined ? { 'query[lootbox_brand_id_eq]': filters?.brandId } : '')
        }
      });
      dispatch(regular.actions.getRegularStatisticsSuccess({ ...response.data, filters: filters }));
    } catch (error) {
      dispatch(regular.actions.hasError('error'));
      dispatch(
        openSnackbar({
          severity: 'error',
          message: "Error. Can't get challenges"
        })
      );
    }
  };
}
