import { useContext } from 'react';

// auth provider
import LootboxAuthContext from 'contexts/LootboxAuthContext';
import GiverAuthContext from 'contexts/GiverAuthContext';

// ==============================|| AUTH HOOKS ||============================== //

export const useAuth = (apiName?: string) => {
  const lootboxContext = useContext(LootboxAuthContext);
  const giverContext = useContext(GiverAuthContext);

  const context = apiName === 'giver' ? giverContext : lootboxContext;
  if (!context) throw new Error('Auth context must be use inside provider');

  return context;
};

export default useAuth;
