// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { TeamOutlined, StarOutlined } from '@ant-design/icons';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = {
  TeamOutlined,
  StarOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const adminSection: NavItemType = {
  id: 'admin-section',
  title: 'Admin section',
  type: 'group',
  children: [
    {
      id: 'admin',
      title: <FormattedMessage id="admin-page" />,
      type: 'item',
      url: '/admins',
      icon: icons.TeamOutlined,
      roles: ['super_admin', 'admin']
    },
    {
      id: 'brand',
      title: <FormattedMessage id="brand-page" />,
      type: 'item',
      url: '/brands',
      icon: icons.StarOutlined
    }
  ]
};

export default adminSection;
